import {
  faAlignJustify,
  faBuilding,
  faLocationDot,
  faMaximize,
  faToilet,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./cardruko.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Cardruko = ({
  carousel, // Dynamically import logo images
  titlerumah,
  subtitlerumah,
  price,
  subprice,
  details,
  whatsappLink,
}) => {
  const settings = {
    dots: carousel.length > 1,
    arrows: carousel.length > 1,
    infinite: carousel.length > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };
  return (
    <div className="rukojudul">
      <div className="cardrumahhome1">
        <div className="card">
          <div className="gambar">
            <Slider {...settings}>
              {carousel.map((image, index) => (
                <img
                  className="cluster-image"
                  key={index}
                  src={image}
                  alt={`Launching ${index + 1}`}
                />
              ))}
            </Slider>
          </div>
          <div className="card-content">
            <div className="container-title-card">
              <div className="titlecard">
                <div className="judul-rumah1">{titlerumah}</div>
                <div className="sub-judul-rumah">
                  <FontAwesomeIcon color="red" icon={faLocationDot} />{" "}
                  {subtitlerumah}
                </div>
                <div className="gridspek">
                  <FontAwesomeIcon icon={faMaximize} />
                  <span> &nbsp; : {details.areas} &nbsp;</span>
                  <FontAwesomeIcon icon={faBuilding} />
                  <span> &nbsp; : {details.buildingSize}&nbsp;</span>
                  <FontAwesomeIcon icon={faToilet} />
                  <span> &nbsp; : {details.bathrooms}&nbsp;</span>
                  <FontAwesomeIcon icon={faAlignJustify} />
                  <span> &nbsp; : {details.lantai}&nbsp;</span>
                </div>
                <div className="cicilan">
                  <div className="start">Start From</div>
                  <div className="det">
                    <div className="angka">{price}</div>
                    <div className="ket">{subprice}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-button">
              <div className="containerwhatsapp">
                <button
                  onClick={() => window.open(whatsappLink, "_blank")}
                  className="whatsapp"
                >
                  <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cardruko;
