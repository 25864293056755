import Enchante1 from "./enchante (1).webp";
import Enchante2 from "./enchante (2).webp";
import Enchante3 from "./enchante (3).webp";
import Enchante4 from "./enchante (4).webp";
import Enchante5 from "./enchante (5).webp";
import Enchante6 from "./enchante (6).webp";
import Enchante7 from "./enchante (7).webp";
import Enchante8 from "./enchante (8).webp";
import Enchante9 from "./enchante (9).webp";
import Enchante10 from "./enchante (10).webp";
import Enchante11 from "./enchante (11).webp";
import Enchante12 from "./enchante (12).webp";
import Enchante13 from "./enchante (13).webp";
import Enchante14 from "./enchante (14).webp";
import Enchante15 from "./enchante (15).webp";
import Enchante16 from "./enchante (16).webp";
import Enchante17 from "./enchante (17).webp";
import Enchante18 from "./enchante (18).webp";
import Enchante19 from "./enchante (19).webp";
import Enchante20 from "./enchante (20).webp";
import Enchante21 from "./enchante (21).webp";
import Enchante22 from "./enchante (22).webp";
import Enchante23 from "./enchante (23).webp";
import Enchante24 from "./enchante (24).webp";

const enchante = [
  Enchante1,
  Enchante2,
  Enchante3,
  Enchante4,
  Enchante5,
  Enchante6,
  Enchante7,
  Enchante8,
  Enchante9,
  Enchante10,
  Enchante11,
  Enchante12,
  Enchante13,
  Enchante14,
  Enchante15,
  Enchante16,
  Enchante17,
  Enchante18,
  Enchante19,
  Enchante20,
  Enchante21,
  Enchante22,
  Enchante23,
  Enchante24,
];

export default enchante;
