import Armont1 from "./armont (1).webp";
import Armont2 from "./armont (2).webp";
import Armont3 from "./armont (3).webp";
import Armont4 from "./armont (4).webp";
import Armont5 from "./armont (5).webp";
import Armont6 from "./armont (6).webp";
import Armont7 from "./armont (7).webp";
import Armont8 from "./armont (8).webp";
import Armont9 from "./armont (9).webp";
import Armont10 from "./armont (10).webp";

const armont = [
  Armont1,
  Armont2,
  Armont3,
  Armont4,
  Armont5,
  Armont6,
  Armont7,
  Armont8,
  Armont9,
  Armont10,
];

export default armont;
