import Adora1 from "./adora (1).webp";
import Adora2 from "./adora (2).webp";
import Adora3 from "./adora (3).webp";
import Adora4 from "./adora (4).webp";
import Adora5 from "./adora (5).webp";
import Adora6 from "./adora (6).webp";
import Adora7 from "./adora (7).webp";
import Adora8 from "./adora (8).webp";
import Adora9 from "./adora (9).webp";
import Adora10 from "./adora (10).webp";
import Adora11 from "./adora (11).webp";
import Adora12 from "./adora (12).webp";
import Adora13 from "./adora (13).webp";
import Adora14 from "./adora (14).webp";
import Adora15 from "./adora (15).webp";

const adora = [
  Adora1,
  Adora2,
  Adora3,
  Adora4,
  Adora5,
  Adora6,
  Adora7,
  Adora8,
  Adora9,
  Adora10,
  Adora11,
  Adora12,
  Adora13,
  Adora14,
  Adora15,
];

export default adora;
