import React from "react";
import "./home.scss";
import Header from "../section/header/header";
import About from "../section/about/about";
import Promo from "../section/promo/promo";
import Rumah from "../section/rumah/rumah.jsx";
import Apartment from "../section/apartment/apartment.jsx";
import Rukokom from "../section/rukocom/rukokom.jsx";
import Lokasi from "../section/lokasi/lokasi.jsx";
import Footer from "../section/footer/footer.jsx";
import ScrollToHashElement from "./ScrollToHashElement.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import tombol from "../asset/tombol.webp";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Othercluster from "../section/othercluster/othercluster.jsx";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62812806111&text=Halo%20Jodie,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20(Promo)%20terbaru%20Rumah%20https://www.bsdcity-navapark.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <About />
      <Promo />
      <Rumah />
      <Apartment />
      <Rukokom />
      <Othercluster />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
