import React from "react";
import "./othercluster.scss";
import card from "./othercluster.json";
import Cardrumah from "../../component/cardother/cardother.jsx";
import enchante from "../../asset/othercluster/enchante/enchante.js";
import armont from "../../asset/othercluster/armont/armont.js";
import imgTresor from "../../asset/othercluster/img-tresor/img-tresor.js";
import adora from "../../asset/othercluster/adora/adora.js";
const imageMap = {
  enchante,
  armont,
  imgTresor,
  adora,
};

const othercluster = () => {
  return (
    <div className="othercluster">
      <div className="container-othercluster">
        <div className="judul-section" id="perumahanlainnya">
          Other Cluster in BSD City
        </div>
        <div className="container-card">
          <div className="cardhome-container">
            {card.cards.map((card, index) => {
              // Determine the logo dynamically
              return (
                <Cardrumah
                  key={index}
                  carousel={imageMap[card.imagesKey]}
                  title={card.title}
                  subtitle={card.subtitle}
                  price={card.price}
                  subprice={card.subprice}
                  details={card.details}
                  whatsappLink={card.whatsappLink} // Pass the WhatsApp link
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default othercluster;
